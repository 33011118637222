import styled from '@emotion/styled';
export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.liteBlue};
  border-radius: 50%;
  width: 48px;
  height: 48px;
`;

export const SignupButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;
  max-width: 700px;
`;
