import * as S from './style';
import Modal from '../Modal';
import Button from '../Button';
import Icon from '../Icon';
import * as T from '../Typography';
import { navRoutes } from '../../constants';

const SSOModals = ({
  errorModalType,
  setIsErrorModalOpen,
  handleLogin,
  errorModalContent,
  account,
}) => {
  const { extension_PreferredName, emails = [] } = account?.idTokenClaims || {};
  if (errorModalType === 'login') {
    return (
      <Modal
        open={!!errorModalType}
        setOpen={setIsErrorModalOpen}
        maxWidth={'800px'}
      >
        <S.ModalContent>
          <S.Circle color="lighterRed">
            <Icon icon="cross" color="red" />
          </S.Circle>
          <T.H2 override="h5" ta="center" mt={4}>
            Can't log in
          </T.H2>
          {extension_PreferredName ? (
            <T.BodyR ta="center" mt="4" mb="5">
              Hi {extension_PreferredName} ({emails?.[0]}),
              <br />
              <br />
              It looks like the Scouts account you signed in with does not exist
              on the Digital Skills platform.
            </T.BodyR>
          ) : (
            <T.BodyR ta="center" mt="4" mb="5">
              {errorModalContent}
            </T.BodyR>
          )}

          <S.SignupButtons>
            <Button
              handleClick={() => setIsErrorModalOpen(false)}
              to={navRoutes.GENERAL.ASSESSMENT}
            >
              Sign up as a Volunteer
            </Button>
            <Button
              handleClick={() => setIsErrorModalOpen(false)}
              to={navRoutes.GENERAL.LANDING}
            >
              Sign up as a Digital Champion
            </Button>
          </S.SignupButtons>
        </S.ModalContent>
      </Modal>
    );
  }

  // if signup
  return (
    <Modal
      open={!!errorModalType}
      setOpen={setIsErrorModalOpen}
      maxWidth={'800px'}
    >
      <S.ModalContent>
        <S.Circle color="lighterRed">
          <Icon icon="cross" color="red" />
        </S.Circle>
        <T.H2 override="h5" ta="center" mt={4}>
          Can't sign up
        </T.H2>
        <T.Body16 ta="center" mt="2" mb="5">
          {errorModalContent}
        </T.Body16>
        <Button mb={1} handleClick={handleLogin}>
          Login
        </Button>
      </S.ModalContent>
    </Modal>
  );
};

export default SSOModals;
