import { useState } from 'react';

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      const _initialValue = item ? JSON.parse(item) : initialValue;
      console.log('===> local storage initialValue', _initialValue);
      return _initialValue;
    } catch (error) {
      console.error('===> Error reading from local storage', error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      setStoredValue(value);
      localStorage.setItem(key, JSON.stringify(value));
      console.log('===> local storage setValue', value);
    } catch (error) {
      console.error('====> Error writing to local storage', error);
    }
  };

  const removeItem = () => {
    try {
      localStorage.removeItem(key);
      setStoredValue(null);
      console.log('===> local storage removeItem');
    } catch (error) {
      console.error('Error removing item from local storage', error);
    }
  };

  return [storedValue, setValue, removeItem];
};

export default useLocalStorage;
