import { useEffect, createContext, useState, useContext } from 'react';
import { Users } from '../api-calls';
import SSOModals from '../components/SSOModals';
import ConfirmSignup from '../components/SSOModals/ConfirmSignup';

import { useHistory } from 'react-router-dom';
import { useAuth } from './auth';
import { useMsal } from '@azure/msal-react';
import { roles, navRoutes } from '../constants';
import { handleClickForAnalytics } from '../helpers';
import moment from 'moment';
import Loading from '../components/Loading';
import useLocalStorage from '../hooks/useLocalStorage';

const SSOContext = createContext({
  SSOResponse: null,
  SSOLogin: () => {},
  SSOLogout: () => {},
  volunteerSignup: () => {},
  digitalChampionSignup: () => {},
  getAccountLoading: false,
});

const SSOProvider = (props) => {
  const [errorModalType, setErrorModalType] = useState(null);
  const [confirmSignupModal, setConfirmSignupModal] = useState(false);
  const [signupRole, setSignupRole] = useState(null);
  const [errorModalContent, setErrorModalContent] = useState('');
  const [getAccountLoading, setGetAccountLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true); // this is the

  const { setUser } = useAuth();
  const history = useHistory();
  const { instance, accounts, inProgress } = useMsal();
  const [authState, setAuthState, removeAuthState] = useLocalStorage(
    'AUTH_STATE',
    null
  );

  console.log('===> authState', authState);

  const getAccount = async () => {
    try {
      setGetAccountLoading(true);
      const response = await instance.acquireTokenSilent({
        account: accounts[0],
      });

      const { idToken, idTokenClaims } = response;
      const { extension_MembershipNumber } = idTokenClaims;

      const { data: user } = await Users.getUserByMembershipNo({
        membershipNo: extension_MembershipNumber,
      });

      return { user, idToken };
    } catch (error) {
      return { user: null, error: error.message };
    } finally {
      setGetAccountLoading(false);
    }
  };

  const idToken = accounts?.[0]?.idToken; // to make sure the useEffect runs (accounts array seems to have teh same reference)
  useEffect(() => {
    const acquireToken = async () => {
      console.log('===> acquireToken : tokens =>', accounts);

      if (idToken) {
        setIsLoading(true); // Show loading before acquiring the token

        const { user, idToken } = await getAccount();

        if (authState?.error) {
          handleAuthError();
          return;
        }

        if (authState?.flow === 'login') {
          handleSSOLoginSuccess(idToken);
        } else if (
          authState?.flow === 'signup' &&
          authState?.role === roles.VOLUNTEER
        ) {
          volunteerSignupSuccess(user, idToken);
        } else if (
          authState?.flow === 'signup' &&
          authState?.role === roles.DIGITAL_CHAMPION
        ) {
          digitalChampionSignupSuccess(user, idToken);
        } else {
          setIsLoading(false);
        }
      } else {
        console.log('===> acquireToken : no accounts');
        setIsLoading(false);
      }
    };

    const handleAuthError = () => {
      console.log('===> handleAuthError');
      setIsLoading(false);

      if (authState?.flow === 'login') {
        console.log('===> handleAuthError : login');

        setErrorModalType('login');
        setErrorModalContent(authState.error);
        removeAuthState();
      } else if (authState?.flow === 'signup') {
        console.log('===> handleAuthError : signup');
        setErrorModalType('signup');
        setErrorModalContent(authState.error);
        removeAuthState();
      }
    };

    if (inProgress === 'none') {
      acquireToken();
      console.log('===> acquireToken');
    } else {
      console.log('===> inProgress', inProgress);
      setIsLoading(true);
    }

    console.log('===> A1 accounts', accounts);

    // TODO: handle when the user go back manually before the ssologin is completed
  }, [idToken, authState, history, instance, inProgress]);

  const SSOLogin = async () => {
    setIsLoading(true);
    await instance.clearCache();
    await instance.loginRedirect({
      prompt: 'select_account',
    });
  };
  const login = async () => {
    setAuthState({ flow: 'login' });
    await SSOLogin();
  };

  const volunteerSignup = async () => {
    if (accounts?.[0]) {
      const { user } = await getAccount();
      if (!user) {
        setConfirmSignupModal(true);
        setSignupRole(roles.VOLUNTEER);
        return;
      }
    }

    setAuthState({ flow: 'signup', role: roles.VOLUNTEER });
    await SSOLogin();
  };

  const volunteerSignupSuccess = async (user, idToken) => {
    if (user) {
      console.log('===> volunteerSignupSuccess : user', user);
      setAuthState({
        ...authState,
        error:
          'An account with this membership already exists. Please log in instead.',
      });
    } else {
      console.log('===> volunteerSignupSuccess : no user');
      removeAuthState();
      setIsLoading(false);

      history.push({
        pathname: navRoutes.VOLUNTEER.SIGNUP,
        state: { idToken },
      });
    }
  };

  const digitalChampionSignup = async () => {
    if (accounts?.[0]) {
      const { user } = await getAccount();
      if (!user) {
        setConfirmSignupModal(true);
        setSignupRole(roles.DIGITAL_CHAMPION);
        return;
      }
    }
    setAuthState({ flow: 'signup', role: roles.DIGITAL_CHAMPION });
    await SSOLogin();
  };

  const digitalChampionSignupSuccess = async (user, idToken) => {
    if (user) {
      setAuthState({
        ...authState,
        error:
          'An account with this membership already exists. Please log in instead.',
      });
    } else {
      removeAuthState();
      setIsLoading(false);

      history.push({
        pathname: navRoutes.DIGITAL_CHAMPION.SIGNUP,
        state: { idToken },
      });
    }
  };

  const logout = async () => {
    setIsLoading(true);
    await instance.clearCache(); // remove accounts from cache to prevent auto login
    try {
      const { error } = await Users.logout();
      if (!error) {
        history.push(navRoutes.GENERAL.HOME);
        removeAuthState();
        setUser({});
        handleClickForAnalytics({
          name: 'logout',
          category: 'Logout',
          action: 'Logout (success)',
        });
      }
      setIsLoading(false);
    } catch (err) {
      handleClickForAnalytics({
        name: 'logout',
        category: 'Logout',
        action: `Logout errors`,
      });
      console.error(err);
    }
  };

  const handleSSOLoginSuccess = async (idToken) => {
    const { data, error } = await Users.login({ idToken });

    if (error) {
      handleClickForAnalytics({
        name: 'login_error',
        category: 'Login',
        action: `Login_errors_${data?.role}`,
      });

      setAuthState({ ...authState, error: error.message });
    } else {
      setUser(data);
      removeAuthState();
      setIsLoading(false);

      handleClickForAnalytics({
        category: 'Login',
        action: `Login_success_${data.role}`,
      });

      const redirectPath = getRedirectPath(data);
      history.push(redirectPath);
    }
  };

  const getRedirectPath = (data) => {
    if (data.role === roles.VOLUNTEER) {
      const diff = moment().diff(moment(data.lastCheckIn), 'days');
      return diff >= 7
        ? navRoutes.VOLUNTEER.CHECK_IN
        : navRoutes.VOLUNTEER.DASHBOARD;
    } else if (data.role === roles.DIGITAL_CHAMPION) {
      return navRoutes.DIGITAL_CHAMPION.DASHBOARD;
    }
    return navRoutes.HQ.DASHBOARD;
  };

  const onConfirmAccount = async () => {
    removeAuthState();
    setIsLoading(false);
    setConfirmSignupModal(false);

    history.push({
      pathname:
        signupRole === roles.VOLUNTEER
          ? navRoutes.VOLUNTEER.SIGNUP
          : navRoutes.DIGITAL_CHAMPION.SIGNUP,
      state: { idToken },
    });
  };

  const onSelectOtherAccount = async () => {
    setAuthState({ flow: 'signup', role: signupRole });
    await SSOLogin();
  };

  const onLoginAfterSignup = async () => {
    if (accounts?.[0]) {
      const { user, idToken } = await getAccount();
      if (user) {
        setErrorModalType(false);
        handleSSOLoginSuccess(idToken);
        return;
      }
    }

    setAuthState({ flow: 'login' });
    await SSOLogin();
  };

  const value = {
    login,
    logout,
    volunteerSignup,
    digitalChampionSignup,
    getAccountLoading,
  };

  return (
    <SSOContext.Provider value={value} {...props}>
      {!isLoading ? (
        <>
          <SSOModals
            errorModalType={errorModalType}
            setIsErrorModalOpen={() => setErrorModalType(null)}
            handleLogin={onLoginAfterSignup}
            errorModalContent={errorModalContent}
            account={accounts?.[0]}
          />

          <ConfirmSignup
            open={confirmSignupModal}
            setOpen={setConfirmSignupModal}
            account={accounts?.[0]}
            onConfirm={onConfirmAccount}
            onSelectOtherAccount={onSelectOtherAccount}
          />

          {props.children}
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
          }}
        >
          <Loading pageLoading={true} />
        </div>
      )}
    </SSOContext.Provider>
  );
};

const useSSO = () => useContext(SSOContext);

export { SSOProvider, useSSO };
export default SSOContext;
