import * as S from './style';
import Modal from '../Modal';
import Button from '../Button';
import Icon from '../Icon';
import * as T from '../Typography';
import { navRoutes } from '../../constants';

const ConfirmSignup = ({
  open,
  setOpen,
  account,
  onConfirm,
  onSelectOtherAccount,
}) => {
  const { emails = [] } = account?.idTokenClaims || {};

  return (
    <Modal open={open} setOpen={setOpen} maxWidth={'800px'}>
      <S.ModalContent>
        <S.Circle color="lighterRed">
          <Icon icon="questionMark" color="red" width="24" height="24" />
        </S.Circle>
        <T.H2 override="h5" ta="center" mt={4}>
          Confirm Account Creation
        </T.H2>
        <T.BodyR ta="center" mt="4" mb="5">
          You are about to create a new account on the Digital Skills platform,
          which will be linked to your Scouts account:{' '}
          <bold>{emails?.[0]}</bold>.
        </T.BodyR>
        <T.BodyR ta="center" mt="4" mb="5">
          Are you sure you want to proceed and link this new account to your
          Scouts login?
        </T.BodyR>

        <S.SignupButtons>
          <Button handleClick={onConfirm} to={navRoutes.GENERAL.ASSESSMENT}>
            Confirm and Create Account
          </Button>
          <Button
            handleClick={onSelectOtherAccount}
            to={navRoutes.GENERAL.ASSESSMENT}
            variant={'outlined'}
            color={'teal'}
          >
            Select a different account
          </Button>
        </S.SignupButtons>
      </S.ModalContent>
    </Modal>
  );
};

export default ConfirmSignup;
